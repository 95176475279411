<template>
    <div class="show-page">
        <search-header @searchFun="searchChange"></search-header>
        <div class="scheme-list">
            <scheme-item v-for="item in list" :key="item.id" :item="item" @showInfo="showInfo"
                @select="select"></scheme-item>
        </div>

        <div class="foot-box" v-if="list.length">
            <div class="btn-box cursor">
                <div class="foot-btn down" @click="downChange">下架</div>
                <div class="foot-btn del" @click="delChange">删除</div>
                <div class="del-box" v-if="showDel">
                    <img src="/assets/images/scheme/delete-bg.png">
                    <div class="del-container">
                        <div class="label">删除确认</div>
                        <div class="content">
                            <img src="/assets/images/scheme/delete-icon.png">
                            <span>此操作将删除选中的【{{ ids.length }}】个搭配，可回收站找回，是否确定删除？</span>
                            <div class="del-btn-box">
                                <div class="del-btn cancel" @click="showDel = false">取消</div>
                                <div class="del-btn confirm" @click="schemeChange('delete')">确定</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <a-pagination class="st-pagination" v-if="pager.total > 0" :current="pager.current" :total="pager.total"
                :page-size="pager.pageSize" :show-total="(total, range) => `${range[0]}-${range[1]},共${total}条数据`"
                @change="gotoPage" />
        </div>
        <scheme-info ref="schemeInfo" delType="delete" @getList="getList"></scheme-info>

        <a-modal v-model="showModal" title="提示" centered @ok="schemeChange('stock')">
            <p>确定要下架选中的{{ ids.length }}个搭配吗？</p>
        </a-modal>
    </div>
</template>

<script>
import api from "@/repo/api";
import SchemeInfo from './components/SchemeInfo.vue';
import searchHeader from './components/SearchHeader.vue';
import SchemeItem from './components/SchemeItem.vue';
export default {
    components: {
        searchHeader, SchemeItem, SchemeInfo
    },
    data() {
        return {
            showDel: false,
            showModal: false,
            list: [],
            gender: 2,
            pager: {
                total: 1,
                current: 1,
                pageSize: 15,
            },
            currentItem: {},
            params: {}
        }
    },
    computed: {
        ids() {
            let arr = [];
            this.list.forEach(item => {
                if (item.active) {
                    arr.push(item.id);
                }
            });
            return arr;
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        select(item) {
            this.$set(item, 'active', !item.active);
        },
        showInfo(item) {
            this.$refs.schemeInfo.open(item.id);
        },
        delChange() {
            let ids = this.ids;
            if (ids.length) {
                this.showDel = true;
            } else {
                this.$message.config({
                    top: `50vh`,
                });
                this.$message.warning('请选择需要删除的搭配');
            }
        },
        downChange() {
            let ids = this.ids;
            if (ids.length) {
                this.showModal = true;
            } else {
                this.$message.config({
                    top: `50vh`,
                });
                this.$message.warning('请选择需要下架的搭配');
            }
        },
        schemeChange(type) {
            this.showDel = false;
            this.showModal = false;
            this.$loading.show();
            let ids = this.ids;
            let data = {
                ids, do: type
            }
            api.post('/ast-scheme/change-scheme', data, (res) => {
                this.$loading.hide();
                if (res.code == 0) {

                    this.$message.config({
                        top: `50vh`,
                    });

                    this.$message.success('操作成功！');
                    this.getList();
                }

            })
        },
        getActive() {
            let arr = [];
            this.list.forEach(item => {
                if (item.active) {
                    arr.push(item.id);
                }
            });
            return arr;
        },
        searchChange(params) {
            this.params = params;
            this.pager = {
                total: 1,
                current: 1,
                pageSize: 15,
            }
            this.getList();
        },
        gotoPage(e) {
            this.pager.current = e;
            this.getList();
        },
        getList() {
            this.$loading.show();
            let data = {
                ...this.params,
                status: 'sale',
                page: this.pager.current
            }
            api.get('/ast-scheme/schemes', data, (res) => {
                this.$loading.hide();
                if (res.code == 0) {
                    this.list = res.data.data;
                    this.pager.total = res.data.total;
                    this.pager.pageSize = res.data.per_page;
                    this.pager.current = res.data.current_page;
                }

            })
        },
    }
}
</script>

<style lang="scss" scoped>
.cursor {
    cursor: pointer;
}

.scheme-list {
    display: flex;
    flex-wrap: wrap;
}

.foot-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 78px;
    margin-bottom: 32px;

    .btn-box {
        display: flex;
        align-items: center;
        position: relative;

        .del-box {
            width: 368px;
            height: 200px;

            img {
                width: 100%;
                height: 100%;
            }

            position: absolute;
            bottom: 48px;
            left: 80px;

            z-index: 999;

            .del-container {
                width: 100%;
                padding-left: 24px;
                padding-top: 24px;
                padding-right: 24px;
                text-align: left;
                position: absolute;
                top: 0;
                left: 0;

                .label {
                    color: #333333;
                    font-size: 16px;
                    line-height: 22px !important;
                    font-weight: 600;
                }

                .content {
                    margin-top: 16px;

                    img {
                        width: 16px;
                        height: 16px;
                        margin-bottom: 4px;
                        margin-right: 4px;
                    }

                    span {
                        color: #333333;
                        font-size: 14px;
                        line-height: 20px !important;
                    }

                    .del-btn-box {
                        display: flex;
                        align-items: center;
                        margin-top: 16px;

                        .del-btn {
                            width: 60px;
                            height: 36px;
                            line-height: 36px;
                            text-align: center;
                            font-size: 14px;
                        }

                        .cancel {
                            color: #333333;
                            border: 1px solid #333333;
                            margin-right: 16px;
                        }

                        .confirm {
                            color: #F5606D;
                            border: 1px solid #F5606D;
                        }
                    }
                }

            }
        }

        .foot-btn {
            width: 80px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 14px;
        }

        .down {
            color: #FFFFFF;
            background: #333333;
            border: 1px solid #333333;
            margin-right: 16px;
        }

        .del {
            color: #F5606D;
            background: #FFFFFF;
            border: 1px solid #F5606D;
        }
    }
}
</style>